import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {AbstractControl, FormControl, FormsModule, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms';
import {ITextFormElement, TTextInputType} from '../form-data.interface';
import {ibanValidator} from "./iban.validator";
import {MatInput} from '@angular/material/input';
import {NgFor, NgIf} from '@angular/common';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';

const
  urlValidator: ValidatorFn = (control: AbstractControl) => {
    if ((<string>control.value) === '') return null;

    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    let valid;
    try {
      valid = (<string>control.value).match(urlRegex);
    } catch {
      valid = false;
    }
    return valid ? null : {invalidUrl: true};
  }

const
  timeIntervalValidator = (control: AbstractControl) => {
    if ((<string>control.value) === '') return null;

    const timeIntervalRegex = /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?(\s*-\s*([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?)(\s*;\s*([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?(\s*-\s*([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?))*$/;

    let valid;
    try {
      valid = (<string>control.value).match(timeIntervalRegex);
    } catch {
      valid = false;
    }
    return valid ? null : {invalidTimeInterval: true};
  }

const
  telValidator: ValidatorFn = (control: AbstractControl) => {
    if ((<string>control.value) === '') return null;
    const telRegex = /^(?:[+][0-9]{2}[\/\s\-\s\ ]?|[(][0-9]{4}[)][\/\s\-\s\ ]?|[0-9]{4}[\/\s\-\s\ ]?)?[0-9]{3,6}[\/\s\-\s\ ]?[0-9]{4,10}$/;
    let valid;
    try {
      valid = (<string>control.value.replaceAll(' ', '')).match(telRegex);
    } catch {
      valid = false;
    }
    return valid ? null : {invalidTel: true};
  }


@Component({
  selector: 'kpi4me-text-element',
  templateUrl: './text-element.component.html',
  styleUrls: ['./text-element.component.scss', '../form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    MatFormField,
    NgIf,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    NgFor,
  ],
})
export class TextElementComponent extends BaseFormElementComponent {
  @ViewChild('input', {static: false}) inputRef: ElementRef;
  @Input() maxLength?: number;
  @Input() min?: number;
  @Input() max?: number;
  @Input() step?: any = 1;
  @Input() textAreaInitialRowCount = 3;
  @Input() patternErrorMessage: string = 'Pattern Error';
  @Input() customValidatorMessage: Map<string, string> = new Map();
  private _row: any;
  @Input() set row(row: any) {
    this._row = row;
    const formControl = this.getFormControl();
    if (row.invalidMessage) {
      formControl.setErrors({linkedTelFieldsError: true});
    }
  }

  private _inputType: TTextInputType;
  @Input() set inputType(t: TTextInputType) {
    if (t) {
      this._inputType = t;

      this.setTypeValidators();
    }
  };

  get inputType() {
    return this._inputType;
  }

  validatorMap: Record<TTextInputType, ValidatorFn[]> = {
    text: [],
    email: [Validators.email],
    url: [urlValidator],
    tel: [telValidator],
    number: [],
    password: [],
    textarea: [],
    iban: [ibanValidator],
  }

  @Input()
  override set data(options: ITextFormElement) {
    this.maxLength = options.maxLength || null;
    this.min = options.min || 0;
    this.max = options.max || null;
    this.inputType = this.inputType ? this.inputType : options.inputType || 'text';

    if (options.min != null) {
      this.getFormControl().addValidators(Validators.min(options.min));
    }
    if (options.max != null) {
      this.getFormControl().addValidators(Validators.max(options.max));
    }
    if (options.maxLength != null) {
      this.getFormControl().addValidators(Validators.maxLength(options.maxLength));
    }

    super.data = options;

    this.getFormControl().updateValueAndValidity({emitEvent: false});
    if ((options.styling) && (options.styling.textAreaInitialRowCount)) {
      this.textAreaInitialRowCount = options.styling.textAreaInitialRowCount;
    }
  }

  private setTypeValidators() {
    if (this.inputType == null) {
      return;
    }

    if (this.getFormControl() == null) {
      throw new Error('Form control not set yet.')
    }

    this.getFormControl().addValidators(this.validatorMap[this.inputType]);
  }

  getErrorMessage(): string[] {
    const formControl = this.getFormControl();
    const errorMessage: string[] = [];
    if (formControl.hasError('required')) {
      errorMessage.push('Bitte füllen Sie dieses Feld aus.');
    }
    if (formControl.hasError('invalidUrl')) {
      errorMessage.push('Der eingegebene Wert ist keine gültige URL.');
    }
    if (formControl.hasError('email')) {
      errorMessage.push('Der eingegebene Wert ist keine gültige EMail-Adresse.');
    }
    if (formControl.hasError('maxlength')) {
      errorMessage.push(`Der eingegebene Wert ist zu lang. Die maximale Anzahl an Zeichen ist ${this.maxLength}.`);
    }
    if (formControl.hasError('max')) {
      errorMessage.push(`Der eingegebene Wert ist zu groß. Der größte zulässige Wert ist ${this.max}.`);
    }
    if (formControl.hasError('min')) {
      errorMessage.push(`Der eingegebene Wert ist zu klein. Der kleinste zulässige Wert ist ${this.min}.`);
    }
    if (formControl.hasError('invalidTel')) {
      errorMessage.push(`Der eingegebene Wert ist keine gültige Telefonnummer.`);
    }
    if (formControl.hasError('invalidIban')) {
      errorMessage.push(`Der eingegebene Wert ist keine gültige IBAN.`);
    }
    if (formControl.hasError('linkedTelFieldsError')) {
      errorMessage.push(`Es müssen stets sowohl Telefonart als auch Telefonnummer angegeben werden.`);
    }
    if (formControl.hasError('invalidTimeInterval')) {
      errorMessage.push(`Die Anfangs- und Endzeit müssen jeweils im Format 'hh' oder 'hh:mm' oder 'hh:mm:ss' angegeben werden.`);
    }
    if (formControl.hasError('invalidMessage')) {
      errorMessage.push(formControl.getError('invalidMessage'));
    }
    if (formControl.hasError('pattern')) {
      errorMessage.push(this.patternErrorMessage);
    }
    this.customValidatorMessage.forEach((value: string, key: string) => {
      if (formControl.hasError(key)) {
        errorMessage.push(value);
      }
    })
    return errorMessage;
  }

  errors(ctrl: FormControl): string[] {
    return ctrl.errors ? Object.keys(ctrl.errors) : [];
  }
}
