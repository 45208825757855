<kpi4me-select-element
  #select
  *ngIf="selectionData() && (selectionData().options?.length > 1 || deleteEnabled() || createNewEnabled())"
  [formControlElement]="selectFormControl()"
  [data]="selectionData()"
  [createNewEnabled]="createNewEnabled()"
  [editEnabled]="false"
  [hideLabel]="true"
  [deleteAlwaysEnabled]="deleteAlwaysEnabled()"
  [deleteEnabled]="deleteEnabled()"
  (newElementClick)="onNewElement($event)"
  (deleteElementClick)="deleteElementClicked($event)"
/>
