const AppControlGuids = {
  "TItemPriceHistoryView": "46E741106BC7441F8B1B09BEAE2D131D",
  "TTemplateConfigurationControl": "FA7E86C4E2814767AC1974704B6044A5",
  "TAllClientList": "25925F5D7E1B4BFEA40D211A1FEE4BCB",
  "TInvoiceTemplateTable": "40DAED4C5CD24EEC86B4B860F9A23801",
  "TInvoiceTemplateConfigForm": "E42762EF7BFF4A6495D6F7C61E9B5211",
  "TDunningInfo": "871D548555F24807BD7953789E820F18",
  "TDunningConfigView": "026642E642A046D0A6CAF86A9ABDDC89",
  "TImportOpenItem": "F327C24AC56B41F39B1EC7DB08CF2AB1",
  "TInvoiceList": "57421BCDE6364802BB19F5C83ED4956D",
  "TOpenItemList": "A41BC1E3AB6043348BEF8897F9F6FC5F",
  "TItemPriceMapping": "B34C35C9A3BD4761919ACE53BA06B5D6",
  "TTestingSettingsConfig": "F2CD95E68E2541AD993A604AFA3C6988",
  "TOnBoardingStateView": "91D1088A52514182AAB04F6510C29C3E",
  "TNotBillable": "32D70EB38E9B4458BFF6803B51F466D7",
  "TPayerTableAllFieldsHidden": "C0AE9FB1EF9F40DF9FB7BEF581D56193",
  "TDataOfficeTableView": "C2B4D4C3246C4451BF38AB647014CA40",
  "TAlternativePayerTable": "CAF7CD78BDFD4555B72AB4DBE451C284",
  "TDTAServiceConfigView": "848AD614FE074ED1BC2388B7AA30C09E",
  "TClientList": "857CB853E1F14BB1BB7D8B894AA9F43A",
  "TOrdinanceList": "47808EF2C0284301A57627758700B428",
  "TItemTable": "F01D6DD01B2C4E18B72DAF16C9110C75",
  "TOutboxBook": "814D814F0A6A4E539D403C74900C186A",
  "TGlobalTemplateConfigForm": "23EAB0B631C94FB1BC544D9C0E5F6375",
  "TPayerDataOfficeTable": "8723D23FFDE547579CDB18093F6EEBC6",
  "TAlternativePayerView": "B6A39F4844C34491969338F175853AFB",
  "TCustomerBasedataConfiguration": "4929B5224456460F9426B4CF6C3D8F09",
  "TCrudCartAdditionaldata": "953A05FDB70646D787410E7112D21BA2",
  "TInvoiceTemplateImportTable": "B45587DE47F74BA8BDC2A244BA7968E1",
  "TAddressInfoView": "76FC5FFC0633479CA9769CB3D17E94E0",
  "TTrapoDetails": "552DE5E36F924904A309DCB77F02B7A0",
  "TOpenItemImportConfig": "E25436236DFA49C8B96A9261AAEC8AC6",
  "TFullCartStatus": "082B811AD7494ECC9C79065B290B86A2",
  "TCartItemsList": "1F4A43B89DED40FB95C3A38FDBC3CC9E",
  "TCartList": "E8ED3F6ED2764F25B29FC702F6B696F8",
  "TCartTable": "F99D661774F94DA8B7F285A0DA2BF32B",
  "TCrewList": "D0D2743760004A6F95906D41BE9F36B0",
  "TClientTable": "26EF37AA16014857B7B698C38CBE39D9",
  "TInternalNotes": "BD87F560679E4A69BCB4BF27C7EF9B50",
  "TTransportList": "B10B1AA744C0440DAB3922314ABAC7A1",
  "TItemgroupTable": "BA258431C7814C8F94A0639AD0BE8DC4",
  "TBusinessAreaMappingTable": "E1F728F40BD947848680B472D2772BCE",
  "TDocumentJobTable": "AEA693FF8733432DBCF00416D3D0A237",
  "TItemGroupView": "7B2825EFBDCF49C5B23E621E5699DE71",
  "TStationTableView": "38B5A384E09B43D98DA51512862043C7",
  "TPayerTableView": "52A0441101AD42C4A7ECA4489B9BB12E",
  "TCollectiveInvoiceTable": "4F661FA20E6D49988E4F38861774C08A",
  "TCartTypeTable": "C35C5F6B87F04640A05CC20FE8895C33",
  "TDunningLevelView": "0696B76D21114E92B365FB40EC4FF9EC",
  "TPayerTable": "A6CAF0CD61DB44F698BA54A53A24A082",
  "TPermanentOrdinanceForClient": "653938F81E1E44D2948231EBA73D6542",
  "TTrapoList": "C9C8028A88D44898936C841703FBC6AD",
  "TItemTypeView": "F428D3BF103143BE86B514CED228E0E4",
  "TEpostConfiguration": "7A24B0B02FBA437A8CB00FA169465421",
  "TBookingOptionsConfig": "6A4893F0A4084A75A6DCA1E16B66301A"
};
export default AppControlGuids;